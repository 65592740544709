import React from 'react'
import PropTypes from 'prop-types'
import LayoutListView from '../components/LayoutListView'
import '../styles/fees.css'

const ComparisonMissionSeek = ({ location }) => (
  <LayoutListView path={location.pathname} configID="4viCSQ7eeFJM88Fjphi0kW">
    <div className="missionseek-content">
      <div className="comparison">
        <table>
          <thead>
            <tr>
              <th className="compare-heading" />
              <th className="compare-heading">
                <h3>Basic Partnership</h3>
                <div>FREE - Members/Associate</div>
              </th>
              <th className="compare-heading">
                <h3>Full Partnership</h3>
                <div>$450 annual subscription</div>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td />
              <td colSpan="2">Unlimited number of opportunities/job vacancies advertised</td>
            </tr>
            <tr className="compare-row">
              <td>Unlimited number of opportunities/job vacancies advertised</td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">Unlimited number of short-term teams advertised</td>
            </tr>
            <tr>
              <td>Unlimited number of short-term teams advertised</td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Unlimited number of short courses and/or mission education programs (Suitable for
                Bible colleges); <span className="exclamation">NEW!</span>
              </td>
            </tr>
            <tr className="compare-row">
              <td>
                Unlimited number of short courses and/or mission education programs (Suitable for
                Bible colleges); <span className="exclamation">NEW!</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Access to Mission Seeker profiles (who have agreed to a privacy clause allowing
                agencies to view their data) and matching your listings against seeker’s alerts.
              </td>
            </tr>
            <tr>
              <td>
                Access to Mission Seeker profiles (who have agreed to a privacy clause allowing
                agencies to view their data) and matching your listings against seeker’s alerts.
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Unlimited advertising of mission events <span className="exclamation">NEW!</span>
              </td>
            </tr>
            <tr className="compare-row">
              <td>
                Unlimited advertising of mission events <span className="exclamation">NEW!</span>
              </td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Multiple users per account <span className="exclamation">NEW!</span>
              </td>
            </tr>
            <tr>
              <td>
                Multiple users per account <span className="exclamation">NEW!</span>
              </td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                <div>
                  Advanced Promotion features: Ability to load video and/or image for opportunities,
                  jobs and volunteer role listings.
                </div>
                <div>
                  (Please note- this feature is available for all partners for short term trips and
                  events)
                </div>
              </td>
            </tr>
            <tr className="compare-row">
              <td>
                <div>
                  Advanced Promotion features: Ability to load video and/or image for opportunities,
                  jobs and volunteer role listings.
                </div>
                <div>
                  (Please note- this feature is available for all partners for short term trips and
                  events)
                </div>
              </td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">Periodically appear as a featured organisation on Mission Seek.</td>
            </tr>
            <tr>
              <td>Periodically appear as a featured organisation on Mission Seek.</td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>

            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Opportunities and Short-term teams featured on MissionSeek front page for a
                pre-determined time frame
              </td>
            </tr>
            <tr className="compare-row">
              <td>
                Opportunities and Short-term teams featured on MissionSeek front page for a
                pre-determined time frame
              </td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Relevant job vacancies advertised in our monthly NFP Update (upon request){' '}
              </td>
            </tr>
            <tr>
              <td>Relevant job vacancies advertised in our monthly NFP Update (upon request)</td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Relevant job vacancies advertised in our monthly National Update (upon request)
              </td>
            </tr>
            <tr className="compare-row">
              <td>
                Relevant job vacancies advertised in our monthly National Update (upon request)
              </td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Dashboard analysis of listings and viewings.{' '}
                <span className="exclamation">NEW!</span>
              </td>
            </tr>
            <tr>
              <td>
                Dashboard analysis of listings and viewings.{' '}
                <span className="exclamation">NEW!</span>
              </td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Promotion of opportunities listed through our Social Media platforms (Instag
                <span className="exclamation">NEW!</span>
              </td>
            </tr>
            <tr className="compare-row">
              <td>
                Promotion of opportunities listed through our Social Media platforms
                (Instagram/Facebook) providing that graphics are provided by the agency{' '}
                <span className="exclamation">NEW!</span>
              </td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Promotion of Events on Social Media ( limited to 2 per year, upon request, with
                graphics provided) <span className="ex">NEW!</span>
              </td>
            </tr>
            <tr>
              <td>
                Promotion of Events on Social Media ( limited to 2 per year, upon request, with
                graphics provided) <span className="ex">NEW!</span>
              </td>
              <td>
                <span className="tickred">✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Access to Application Tracking module (for applications via Mission Seek only){' '}
                <span className="ex">NEW!</span>
              </td>
            </tr>
            <tr className="compare-row">
              <td>
                Access to Application Tracking module (for applications via Mission Seek only){' '}
                <span className="ex">NEW!</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Access to Application Tracking module (for applications via any other source){' '}
                <span className="ex">NEW!</span>
              </td>
            </tr>
            <tr>
              <td>
                Access to Application Tracking module (for applications via any other source){' '}
                <span className="ex">NEW!</span>
              </td>
              <td>
                <span className="tickred"> ✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
            <tr>
              <td>&nbsp;</td>
              <td colSpan="2">
                Advanced feature: Ability to feed Mission Seek data on your website (for your data
                only). Great time saver – enter data once for both Mission Seek and your website.{' '}
                <span className="ex">NEW!</span>
              </td>
            </tr>
            <tr className="compare-row">
              <td>
                Advanced feature: Ability to feed Mission Seek data on your website (for your data
                only). Great time saver – enter data once for both Mission Seek and your website.{' '}
                <span className="ex">NEW!</span>
              </td>
              <td>
                <span className="tickred"> ✘</span>
              </td>
              <td>
                <span className="tickgreen">✔</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </LayoutListView>
)

export default ComparisonMissionSeek

ComparisonMissionSeek.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
