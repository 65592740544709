import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Layout from './layout'
import SEO from './seo'
import HeroImage from './HeroImage'
import richTextOptions from '../shared/richTextOptions'
import getPageConfig from '../shared/getPageConfig'

const LayoutListView = ({ children, path, configID }) => {
  const { title, image, before, after, seoKeywords, seoDescription, largeImage } = getPageConfig(
    configID
  )
  const imgUrl = image && 'file' in image ? image.file.url : null

  return (
    <Layout path={path}>
      <SEO title={title} description={seoDescription} />
      <HeroImage src={imgUrl} alt={title} title={title} isLargeImage={largeImage} />
      <div className="container-wide container">
        {before && (
          <div className="text-content">
            {documentToReactComponents(before.json, richTextOptions)}
          </div>
        )}
        {children}
        {after && (
          <div className="text-content">
            {documentToReactComponents(after.json, richTextOptions)}
          </div>
        )}
      </div>
    </Layout>
  )
}

export default LayoutListView
