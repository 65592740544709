import React from 'react'
import PropTypes from 'prop-types'
import defaultHeroImage from '../../images/home-sm.png'

// Add to url when pulling from Contentful '?fm=jpg&fl=progressive&w=1920&q=60'

const HeroImage = ({ src = defaultHeroImage, alt, title, isLargeImage }) => {
  const style = !isLargeImage ? { height: '30vh' } : { height: '60vh' }
  const resizeImage = !isLargeImage
    ? '?fm=jpg&fl=progressive&w=1920&h=500&fit=fill&q=70'
    : '?fm=jpg&fl=progressive&w=1920&q=70'
  return (
    <div className="hero-post">
      <img src={src + resizeImage} alt={alt} style={style} className="fade-in" />
      <div className="hero-post-text">
        <h1 style={{ color: 'white' }}>{title}</h1>
      </div>
    </div>
  )
}

export default HeroImage

// HeroImage.propTypes = {
//   src: PropTypes.string.isRequired,
//   alt: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired
// }
