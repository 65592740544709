import { useStaticQuery, graphql } from 'gatsby'

export default function getPageConfig(configID) {
  const queryData = useStaticQuery(graphql`
    {
      allContentfulPageConfig(sort: { fields: description, order: ASC }) {
        edges {
          node {
            contentful_id
            title
            description
            largeImage
            image {
              file {
                url
              }
            }
            before {
              json
            }
            after {
              json
            }
            seoKeywords
            seoDescription
          }
        }
      }
    }
  `)

  const data = queryData.allContentfulPageConfig.edges.find(
    item => item.node.contentful_id === configID
  )

  if (!data) console.log(`Page Config: ${configID} not found.`, data)
  return data.node
}
